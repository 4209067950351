/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import {Box, Button, Typography} from '@material-ui/core';
import { IFacility } from 'models/facility';
import { Dispatch, FC, MouseEvent, SetStateAction } from 'react';
import { FormattedMessage as M } from 'react-intl';
import { useStore } from 'stores/store-hooks';
import { ExpandButton } from '../../common/expand-button';
import { SettingsButton } from '../settings-button';
import {Videocam} from "@material-ui/icons";
import {useHistory} from "react-router-dom";

interface IFacilityRowHeader {
  expanded?: boolean;
  facility: IFacility;
  hasIot: boolean;
  setFacilityDialog: Dispatch<
    SetStateAction<{
      visible: boolean;
      editedFacility: IFacility | null;
    }>
  >;
}

const ContentLeft = styled.div`
  flex-grow: 1;
  cursor: default;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;
const ContentRight = styled.div`
  display: flex;
  cursor: default;
  align-items: center;
`;
const BasinCount = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(5)}px;
  > h1 {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`;
const FacilityCount = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(5)}px;
  > h1 {
    margin-right: ${({ theme }) => theme.spacing(2)}px;
  }
`;
const FacilitySettings = styled.div<{ canEdit: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, canEdit }) =>
    !canEdit ? '#CBCBCB' : theme.palette.primary.main};
`;

export const FacilityRowHeader: FC<IFacilityRowHeader> = observer(
  ({ facility, setFacilityDialog, expanded, hasIot }) => {
    const { companiesById } = useStore('companyStore');
    const userStore = useStore('userStore');
    const { canEdit } = userStore;

    const history = useHistory();

    const handleEdit = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      setFacilityDialog({ visible: true, editedFacility: facility });
    };

    const openFacilityCameraView = (facility: IFacility) => {
      history.push(`/facility/${facility.id}`);
    };

    return (
      <Box
        css={css`
          display: flex;
          flex-grow: 1;
          flex-direction: row;
          align-items: center;
        `}
      >
        <ExpandButton isExpanded={!!expanded} />
        <ContentLeft
          key={facility.id}
          onClick={(event: any) => event.stopPropagation()}
          onFocus={(event: any) => event.stopPropagation()}
        >
          {
            userStore.localLang === 'ru' ?
              <Typography variant="subtitle2" color="primary">
                {facility.name}
              </Typography> :
              <Box>
                {facility.company && companiesById[facility.company.id] ? (
                  <Box display="flex" flexDirection="row">
                    <Typography  variant="subtitle2" color="primary">
                      {companiesById[facility.company.id]?.name}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
          }
        </ContentLeft>
        <ContentRight
          onClick={(event: any) => event.stopPropagation()}
          onFocus={(event: any) => event.stopPropagation()}
        >
          {hasIot && (
            <Button
              onClick={() => openFacilityCameraView(facility)}
              style={{
                margin: '15px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Videocam/>
            </Button>
          )}
          <BasinCount>
            <Typography variant="h1" color="primary" style={{ fontSize: 26 }}>
              {facility.sites?.length}
            </Typography>
            <Typography variant="h6" color="primary">
              <M id="SiteCountBR" values={{ br: <br /> }} />
            </Typography>
          </BasinCount>
          <FacilityCount>
            <Typography variant="h1" color="primary" style={{ fontSize: 26 }}>
              {facility.sites?.reduce((count, site) => {
                return count + site?.basin_count || 0;
              }, 0)}
            </Typography>
            <Typography variant="h6" color="primary">
              <M id="BasinCountBR" values={{ br: <br /> }} />
            </Typography>
          </FacilityCount>
          <FacilitySettings canEdit={canEdit}>
            <Typography variant="h6">
              <M id="FacilitySettings" values={{ br: <br /> }} />
            </Typography>
            <SettingsButton
              disabled={!canEdit}
              onClick={handleEdit}
              disabledTooltipTextId={'ModifyingSettingsNotAllowed'}
            />
          </FacilitySettings>
        </ContentRight>
      </Box>
    );
  }
);
