import React, {FC} from 'react';
import {RemovedFishReport} from './removed-fish/removed-fish-report';
import {Box, Button, Typography} from '@material-ui/core';
import {Accordion} from '../accordion';
import {useStore} from '../../stores/store-hooks';
import {ICompany} from '../../models/company';
import {FormattedMessage as M} from 'react-intl';
import {useTheme} from "@emotion/react";

interface IReportType {
  id: number;
  name: string;
  Component: FC<ICompany>;
}

const reportTypes: Array<IReportType> = [
  {
    id: 1,
    name: 'RemovedFishReportName',
    Component: RemovedFishReport,
  },
];

export const ReportsList: FC<ICompany> = (company) => {
  const reportStore = useStore('reportStore');
  const theme = useTheme();

  const onReportExpand = async (expanded: boolean) => {
    if (!expanded) return;
    reportStore.loadReportForCompany(company.id);
  };

  return (
    <Box width="100%">
      {reportTypes.map((reportType) => (
        <Accordion
          onExpandCallback={(expanded) => onReportExpand(expanded)}
          key={reportType.id}
          id={reportType.id}
          expandIcon={true}
          isExpandedInitially={false}
          defaultBorder={theme.palette.line1.main}
          defaultBgColor={theme.palette.background2.main}
          accordionHeader={
            <Button fullWidth style={{justifyContent: "flex-start", height: "100%"}}>
              <Typography variant="h2">
                <M id={reportType.name} />
              </Typography>
            </Button>
          }
          accordionContent={<reportType.Component id={company.id} />}
        />
      ))}
    </Box>
  );
};
